'use client';
import { SessionProvider } from 'next-auth/react';
import { ThemeProvider } from 'next-themes';
import { ReCaptchaProvider } from "next-recaptcha-v3";
import { Cache, SWRConfig } from 'swr';

type ProvidersProps = {
  children: React.ReactNode;
};

const localStorageProvider = (cache: Cache<any>) => {
  const map = new Map(JSON.parse(localStorage.getItem("app-cache") || "[]"));
  window.addEventListener("beforeunload", () => {
    if (window.location.search.includes("clear-cache")) {
      console.warn("Clearing cache");
      localStorage.removeItem("app-cache");
      return;
    }
    const appCache = JSON.stringify(Array.from(map.entries()));
    localStorage.setItem("app-cache", appCache);
  });

  return map as Cache<any>;
};

export default function Providers({ children }: ProvidersProps) {
  return (
    <SessionProvider>
        <ThemeProvider attribute="class" defaultTheme='dark'>
          <ReCaptchaProvider >
            <SWRConfig
              value={{
                provider: localStorageProvider
              }}
            >
              {children}
            </SWRConfig>
          </ReCaptchaProvider>
        </ThemeProvider>
    </SessionProvider>
  );
}