// create theme switcher
import { MoonIcon, SunIcon } from '@radix-ui/react-icons';
import { IconButton, useThemeContext } from '@radix-ui/themes';
import { useTheme } from 'next-themes';

export default function ThemeSwitcher() {
    const theme = useTheme();
    function handleThemeSwitch() {
        theme.setTheme(theme.resolvedTheme === 'dark' ? 'light' : 'dark');
    }
    return (
        <>
            <IconButton className={'rounded-full'} variant='surface' title='เปลี่ยนธีม' onClick={handleThemeSwitch}>
                {
                    theme.resolvedTheme === 'dark' ? (
                        <SunIcon width={20} height={20} />
                    ) : (
                        <MoonIcon width={20} height={20} />
                    )
                }
            </IconButton>
        </>
    );
}