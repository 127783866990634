import(/* webpackMode: "eager" */ "/app/node_modules/@next/third-parties/dist/google/ga.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@next/third-parties/dist/google/gtm.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
;
import(/* webpackMode: "eager", webpackExports: ["AccessibleIcon"] */ "/app/node_modules/@radix-ui/themes/dist/esm/components/accessible-icon.js");
;
import(/* webpackMode: "eager", webpackExports: ["AlertDialog","AlertDialogRoot","AlertDialogTrigger","AlertDialogContent","AlertDialogTitle","AlertDialogDescription","AlertDialogAction","AlertDialogCancel"] */ "/app/node_modules/@radix-ui/themes/dist/esm/components/alert-dialog.js");
;
import(/* webpackMode: "eager", webpackExports: ["AspectRatio"] */ "/app/node_modules/@radix-ui/themes/dist/esm/components/aspect-ratio.js");
;
import(/* webpackMode: "eager", webpackExports: ["Avatar"] */ "/app/node_modules/@radix-ui/themes/dist/esm/components/avatar.js");
;
import(/* webpackMode: "eager", webpackExports: ["Callout","CalloutRoot","CalloutIcon","CalloutText"] */ "/app/node_modules/@radix-ui/themes/dist/esm/components/callout.js");
;
import(/* webpackMode: "eager", webpackExports: ["Checkbox"] */ "/app/node_modules/@radix-ui/themes/dist/esm/components/checkbox.js");
;
import(/* webpackMode: "eager", webpackExports: ["ContextMenu","ContextMenuRoot","ContextMenuTrigger","ContextMenuContent","ContextMenuLabel","ContextMenuItem","ContextMenuGroup","ContextMenuRadioGroup","ContextMenuRadioItem","ContextMenuCheckboxItem","ContextMenuSub","ContextMenuSubTrigger","ContextMenuSubContent","ContextMenuSeparator"] */ "/app/node_modules/@radix-ui/themes/dist/esm/components/context-menu.js");
;
import(/* webpackMode: "eager", webpackExports: ["Dialog","DialogRoot","DialogTrigger","DialogContent","DialogTitle","DialogDescription","DialogClose"] */ "/app/node_modules/@radix-ui/themes/dist/esm/components/dialog.js");
;
import(/* webpackMode: "eager", webpackExports: ["DropdownMenu","DropdownMenuRoot","DropdownMenuTrigger","DropdownMenuContent","DropdownMenuLabel","DropdownMenuItem","DropdownMenuGroup","DropdownMenuRadioGroup","DropdownMenuRadioItem","DropdownMenuCheckboxItem","DropdownMenuSub","DropdownMenuSubTrigger","DropdownMenuSubContent","DropdownMenuSeparator"] */ "/app/node_modules/@radix-ui/themes/dist/esm/components/dropdown-menu.js");
;
import(/* webpackMode: "eager", webpackExports: ["HoverCard","HoverCardRoot","HoverCardTrigger","HoverCardContent"] */ "/app/node_modules/@radix-ui/themes/dist/esm/components/hover-card.js");
;
import(/* webpackMode: "eager", webpackExports: ["Popover","PopoverRoot","PopoverTrigger","PopoverContent","PopoverClose"] */ "/app/node_modules/@radix-ui/themes/dist/esm/components/popover.js");
;
import(/* webpackMode: "eager", webpackExports: ["Portal"] */ "/app/node_modules/@radix-ui/themes/dist/esm/components/portal.js");
;
import(/* webpackMode: "eager", webpackExports: ["RadioGroup","RadioGroupRoot","RadioGroupItem"] */ "/app/node_modules/@radix-ui/themes/dist/esm/components/radio-group.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@radix-ui/themes/dist/esm/components/scroll-area.js");
;
import(/* webpackMode: "eager", webpackExports: ["Select","SelectRoot","SelectTrigger","SelectContent","SelectItem","SelectGroup","SelectLabel","SelectSeparator"] */ "/app/node_modules/@radix-ui/themes/dist/esm/components/select.js");
;
import(/* webpackMode: "eager", webpackExports: ["Separator"] */ "/app/node_modules/@radix-ui/themes/dist/esm/components/separator.js");
;
import(/* webpackMode: "eager", webpackExports: ["Slider"] */ "/app/node_modules/@radix-ui/themes/dist/esm/components/slider.js");
;
import(/* webpackMode: "eager", webpackExports: ["Switch"] */ "/app/node_modules/@radix-ui/themes/dist/esm/components/switch.js");
;
import(/* webpackMode: "eager", webpackExports: ["Tabs","TabsRoot","TabsList","TabsTrigger","TabsContent"] */ "/app/node_modules/@radix-ui/themes/dist/esm/components/tabs.js");
;
import(/* webpackMode: "eager", webpackExports: ["TextField","TextFieldRoot","TextFieldSlot","TextFieldInput"] */ "/app/node_modules/@radix-ui/themes/dist/esm/components/text-field.js");
;
import(/* webpackMode: "eager", webpackExports: ["Tooltip"] */ "/app/node_modules/@radix-ui/themes/dist/esm/components/tooltip.js");
;
import(/* webpackMode: "eager", webpackExports: ["VisuallyHidden"] */ "/app/node_modules/@radix-ui/themes/dist/esm/components/visually-hidden.js");
;
import(/* webpackMode: "eager", webpackExports: ["ThemePanel"] */ "/app/node_modules/@radix-ui/themes/dist/esm/theme-panel.js");
;
import(/* webpackMode: "eager", webpackExports: ["Theme","useThemeContext","updateThemeAppearanceClass"] */ "/app/node_modules/@radix-ui/themes/dist/esm/theme.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"src/components/font-styler.tsx\",\"import\":\"Sarabun\",\"arguments\":[{\"subsets\":[\"latin\"],\"weight\":[\"400\",\"500\",\"600\",\"700\"],\"display\":\"swap\"}],\"variableName\":\"sarabun\"}");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"src/components/font-styler.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"],\"weight\":[\"400\",\"500\",\"600\",\"700\"],\"display\":\"swap\"}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"src/components/font-styler.tsx\",\"import\":\"Source_Sans_3\",\"arguments\":[{\"subsets\":[\"latin\"],\"weight\":[\"400\",\"500\",\"600\",\"700\"],\"display\":\"swap\"}],\"variableName\":\"sourceSans3\"}");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"src/components/font-styler.tsx\",\"import\":\"Noto_Serif_Thai\",\"arguments\":[{\"subsets\":[\"thai\"],\"weight\":[\"400\",\"500\",\"600\",\"700\"]}],\"variableName\":\"notoSerifThai\"}");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"src/components/font-styler.tsx\",\"import\":\"Noto_Sans_Thai\",\"arguments\":[{\"subsets\":[\"thai\"],\"weight\":[\"400\",\"500\",\"600\",\"700\"]}],\"variableName\":\"notoSansThai\"}");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"src/components/font-styler.tsx\",\"import\":\"Chakra_Petch\",\"arguments\":[{\"subsets\":[\"thai\"],\"weight\":[\"400\",\"500\",\"600\",\"700\"]}],\"variableName\":\"chakraPetch\"}");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"src/components/font-styler.tsx\",\"import\":\"Bai_Jamjuree\",\"arguments\":[{\"subsets\":[\"thai\"],\"weight\":[\"400\",\"500\",\"600\",\"700\"]}],\"variableName\":\"baijamjuree\"}");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Sarabun\",\"arguments\":[{\"subsets\":[\"thai\"],\"weight\":[\"400\",\"500\",\"600\",\"700\"],\"display\":\"swap\"}],\"variableName\":\"sarabun\"}");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@radix-ui/themes/styles.css");
;
import(/* webpackMode: "eager" */ "/app/src/app/global.css");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"src/components/Footer/Footer.tsx\",\"import\":\"Kanit\",\"arguments\":[{\"subsets\":[\"latin\"],\"weight\":[\"400\",\"500\",\"600\",\"700\"],\"display\":\"swap\"}],\"variableName\":\"kanit\"}");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/CookieConsent/CookieConsent.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/Navbar/Navbar.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/components/providers.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "/app/src/components/ui/sonner.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ThemeControl"] */ "/app/src/lib/theme.tsx");
