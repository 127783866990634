"use client"
import { useState, useEffect } from "react";
import { cn } from "@/lib/utils";
import { CookieIcon } from "@radix-ui/react-icons";
import { Button } from "../ui/button";

export default function CookieConsent({ demo = false, onAcceptCallback = () => { }, onDeclineCallback = () => { } }) {
    const [isOpen, setIsOpen] = useState(false);
    const [hide, setHide] = useState(false);

    const accept = () => {
        setIsOpen(false);
        document.cookie = "cookieConsent=true; expires=Fri, 31 Dec 9999 23:59:59 GMT";
        setTimeout(() => {
            setHide(true);
        }, 700);
        onAcceptCallback();
    };

    const decline = () => {
        setIsOpen(false);
        setTimeout(() => {
            setHide(true);
        }, 700);
        onDeclineCallback();
    };

    useEffect(() => {
        try {
            setIsOpen(true);
            if (document.cookie.includes("cookieConsent=true")) {
                if (!demo) {
                    setIsOpen(false);
                    setTimeout(() => {
                        setHide(true);
                    }, 700);
                }
            }
        }
        catch (e) {
            // console.log("Error: ", e);
        }
    }, []);

    return (
        <div className={cn("fixed z-[200] bottom-0 left-0 right-0 sm:left-4 sm:bottom-4 w-full sm:max-w-md transition-transform duration-700", !isOpen ? "transition-[opacity,transform] translate-y-8 opacity-0" : "transition-[opacity,transform] translate-y-0 opacity-100", hide && "hidden")}>
            <div className="bg-secondary rounded-md m-2">
                <div className="grid gap-2">
                    <div className="border-b border-border h-14 flex items-center justify-between p-4">
                        <h1 className="text-lg font-medium pt-5">เราให้ความสำคัญกับความเป็นส่วนตัวของคุณ</h1>
                        <CookieIcon className="h-[1.2rem] w-[1.2rem]" />
                    </div>
                    <div className="p-4">
                        <p className="text-sm font-normal">
                            เราใช้คุกกี้เพื่อปรับปรุงประสบการณ์การใช้งานของคุณบนเว็บไซต์ของเรา คุกกี้คือข้อมูลที่เราเก็บไว้ในเครื่องของคุณเพื่อช่วยให้คุณไม่ต้องเข้าสู่ระบบหรือกรอกข้อมูลซ้ำๆ ในการเข้าชมเว็บไซต์ของเราในครั้งถัดไป
                            <br />
                            <br />
                            <span className="text-xs">ในการกด &quot;<span className="font-medium opacity-80">ยอมรับ</span>&quot; หมายความว่าคุณยอมรับการใช้คุกกี้ของเรา</span>
                            <br />
                        </p>
                    </div>
                    <div className="flex gap-2 p-4 py-5 border-t border-border bg-background/20">
                        <Button onClick={accept} className="w-full">ยอมรับ</Button>
                        <Button onClick={decline} className="w-full" variant="ghost">ปฏิเสธ</Button>
                    </div>
                </div>
            </div>
        </div>
    )
}