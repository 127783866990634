'use client';
import * as NavigationMenu from '@radix-ui/react-navigation-menu';
import React, { useEffect } from 'react';
import { EnterIcon, HamburgerMenuIcon } from '@radix-ui/react-icons';
import { IconButton, Text } from '@radix-ui/themes';
import Image from 'next/image';
import { Sarabun } from 'next/font/google';
import { signOut, useSession } from 'next-auth/react';
import { useWindowSize } from '@/lib/size';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { usePathname, useRouter } from 'next/navigation';
import { role } from '@prisma/client';
import ThemeSwitcher from '../ThemeSwitcher/ThemeSwitcher';

const sarabun = Sarabun({ subsets: ['latin'], weight: ['400', '500', '600', '700'], display: 'swap' })
    
type NavbarProps = {
    className?: string;
};

export default function Navbar(props: NavbarProps) {
    const router = useRouter();
    const pathname = usePathname();
    const { data: session, status } = useSession();

    useEffect(() => {
        const kongdropdown = document.getElementById('kongdropdown');
        if (kongdropdown) {
            if (pathname === '/auth/signin') {
                kongdropdown.remove();
            } else {
                kongdropdown.classList.remove('hidden');
            }
        }
    }, [pathname]);

    return (
        <NavigationMenu.Root className={"relative flex w-full bg-[var(--indigo-7)] shadow-lg h-fit py-2 "+ sarabun.className}>
            <div className="px-9 w-[100vw]">
                {/* left grouo */}
                <NavigationMenu.List className='float-left inline-flex gap-2'>
                    <NavigationMenu.Item className='items-center my-1 flex gap-1.5 mr-1'>
                        <Image className='mx-auto my-auto' aria-hidden height={23} width={23} src='/logo.png' alt='logo' />
                        <Text className='leading-1' size={'3'} weight={'bold'}>
                            ระบบจองห้อง
                        </Text>
                    </NavigationMenu.Item>
                    <NavigationMenu.Item className='hidden sm:flex hover:transition-all hover:duration-250 hover:ease-in-out hover:transform hover:scale-105 text-[var(--indigo-12)] hover:text-[var(--gray-12)]'>
                        <NavigationMenu.Link
                            className="rounded-sm px-3 py-2 text-base leading-none no-underline outline-none focus:shadow-[0_0_0_2px] flex gap-1"
                            href="/"
                        >
                            หน้าหลัก
                        </NavigationMenu.Link>
                    </NavigationMenu.Item>
                    <NavigationMenu.Item className='hidden sm:flex hover:transition-all hover:duration-250 hover:ease-in-out hover:transform hover:scale-105 text-[var(--indigo-12)] hover:text-[var(--gray-12)]'>
                        <NavigationMenu.Link
                            className="rounded-sm px-3 py-2 text-base leading-none no-underline outline-none focus:shadow-[0_0_0_2px] flex gap-1"
                            href="/reserve"
                        >
                            จองห้อง
                        </NavigationMenu.Link>
                    </NavigationMenu.Item>
                    <NavigationMenu.Item className='hidden sm:flex hover:transition-all hover:duration-250 hover:ease-in-out hover:transform hover:scale-105 text-[var(--indigo-12)] hover:text-[var(--gray-12)]'>
                        <NavigationMenu.Link
                            className="rounded-sm px-3 py-2 text-base leading-none no-underline outline-none focus:shadow-[0_0_0_2px] flex gap-1"
                            href="/calendar"
                        >
                            ปฏิทินการใช้ห้อง
                        </NavigationMenu.Link>
                    </NavigationMenu.Item>
                    {/* <NavigationMenu.Item aria-disabled className='hidden md:inline-flex hover:bg-[var(--ruby-12)] hover:transition-all hover:duration-250 hover:ease-in-out hover:transform hover:scale-105 border border-[var(--ruby-11)] rounded'>
                        <NavigationMenu.Link
                            className="text-[var(--ruby-12)] hover:text-[var(--ruby-6)] px-3 py-2 text-[15px] font-medium leading-none no-underline outline-none focus:shadow-[0_0_0_2px] flex gap-1"
                            href="#"
                        >
                            ระบบแจ้งซ่อม
                        </NavigationMenu.Link>
                    </NavigationMenu.Item> */}
                </NavigationMenu.List>
                {/* dropdown menu */}
                <div className={'float-right inline-flex'} id="kongdropdown">
                    <DropdownMenu.Root>
                        <DropdownMenu.Trigger className='inline-flex justify-center items-center' asChild>
                            <IconButton variant='surface' title='เมนูเพิ่มเติม'>
                                <HamburgerMenuIcon width={18} height={18} />
                            </IconButton>
                        </DropdownMenu.Trigger>
                        <DropdownMenu.Content className='mt-2 w-[100vw] rounded-none sm:w-fit sm:rounded-md z-50 p-4 bg-[var(--indigo-6)]'>
                            {
                                status === 'authenticated' ? (
                                    <>
                                        <DropdownMenu.Item className='outline-none'>
                                            <div className='mx-5 mb-4'>
                                                <Text className='text-[var(--indigo-12)] font-light' size={'3'}>
                                                    ชื่อผู้ใช้งาน : <Text className='text-[var(--indigo-11)] font-bold' size={'3'}>{session.user.name}</Text>
                                                </Text>
                                                <br />
                                                {/* สิทธิ์การเข้าถึง */}
                                                <Text className='text-[var(--indigo-12)] font-light' size={'3'}>
                                                    สิทธิ์การเข้าถึง : <Text className='text-[var(--indigo-11)] font-bold' size={'3'}>{
                                                        session.user.role === role.SUPERADMIN ? 'ผู้ดูแลระบบ' : session.user.role === role.ADMIN ? 'เจ้าหน้าที่' : session.user.role === role.STUDENT ? 'นักศึกษา' : 'บุคลากร'
                                                    }</Text>
                                                </Text>
                                            </div>
                                        </DropdownMenu.Item>
                                    </>
                                ) : (<></>)
                            }
                            <DropdownMenu.Item className='rounded-full transition-all md:hidden px-6 p-2 text-base text-[var(--indigo-12)] hover:bg-[var(--indigo-12)] hover:text-[var(--indigo-6)] hover:outline-none border-[var(--indigo-11)] mt-1 border hover:cursor-pointer hover:transition-all hover:duration-500 hover:ease-in-out hover:transform font-bold scale-95 hover:scale-100' onClick={() => {
                                router.push('/quick-guide.pdf')
                            }}>
                                คู่มือการใช้งาน
                            </DropdownMenu.Item>
                            {
                                session != undefined && (session.user.role === role.ADMIN || session.user.role === role.SUPERADMIN) ? (
                                    <DropdownMenu.Item className='hidden lg:flex rounded-full transition-all px-6 p-2 text-base text-[var(--indigo-12)] hover:bg-[var(--indigo-12)] hover:text-[var(--indigo-6)] hover:outline-none border-[var(--indigo-11)] mt-1 border hover:cursor-pointer hover:transition-all hover:duration-500 hover:ease-in-out hover:transform font-bold scale-95 hover:scale-100' onClick={() => {
                                        router.push('/admin')
                                    }}>
                                        จัดการระบบ
                                    </DropdownMenu.Item>
                                ) : (<></>)
                            }
                            {
                                status === 'authenticated' ? (
                                    <>
                                        <DropdownMenu.Item className='sm:hidden rounded-full px-6 p-2 text-base outline-none hover:cursor-pointer mt-1 hover:transition-all hover:duration-500 hover:ease-in-out hover:transform text-[var(--indigo-12)] hover:text-[var(--gray-12)] hover:bg-[var(--indigo-5)] font-bold scale-95 hover:scale-100' onClick={() => {
                                            router.push('/')
                                        }}>
                                            หน้าหลัก
                                        </DropdownMenu.Item>
                                        <DropdownMenu.Item className='sm:hidden rounded-full px-6 p-2 text-base outline-none hover:cursor-pointer mt-1 hover:transition-all hover:duration-500 hover:ease-in-out hover:transform text-[var(--indigo-12)] hover:text-[var(--gray-12)] hover:bg-[var(--indigo-5)] font-bold scale-95 hover:scale-100' onClick={() => {
                                            router.push('/reserve')
                                        }}>
                                            จองห้อง
                                        </DropdownMenu.Item>
                                        <DropdownMenu.Item className='sm:hidden rounded-full px-6 p-2 text-base outline-none hover:cursor-pointer mt-1 hover:transition-all hover:duration-500 hover:ease-in-out hover:transform text-[var(--indigo-12)] hover:text-[var(--gray-12)] hover:bg-[var(--indigo-5)] font-bold scale-95 hover:scale-100' onClick={() => {
                                            router.push('/calendar')
                                        }}>
                                            ปฏิทินการใช้ห้อง
                                        </DropdownMenu.Item>
                                        <DropdownMenu.Item className='rounded-full transition-all px-6 p-2 text-base text-[var(--ruby-12)] hover:outline-none border-[var(--ruby-11)] mt-1 border hover:cursor-pointer hover:transition-all hover:duration-500 hover:ease-in-out hover:transform font-bold scale-95 hover:scale-100' onClick={() => {signOut()}}>
                                            ออกจากระบบ
                                        </DropdownMenu.Item>
                                    </>
                                ) : (
                                    <DropdownMenu.Item className='rounded-full transition-all px-6 p-2 text-base text-[var(--indigo-12)] hover:outline-none border-[var(--indigo-11)] mt-1 border hover:cursor-pointer hover:transition-all hover:duration-500 hover:ease-in-out hover:transform font-bold scale-95' onClick={() => {
                                        router.push('/auth/signin')
                                    }}>
                                        เข้าสู่ระบบ
                                    </DropdownMenu.Item>
                                )
                            }
                        </DropdownMenu.Content>
                    </DropdownMenu.Root>
                </div>
                <div className='mx-2 float-right marker:hidden'>
                    <ThemeSwitcher />
                </div>
                {/* right group */}
                <NavigationMenu.List className='justify-center items-center float-right inline-flex mr-1 ml-auto gap-3'>
                    <NavigationMenu.Item className='hidden md:inline-flex hover:bg-[var(--indigo-12)] hover:transition-all hover:duration-250 hover:ease-in-out hover:transform hover:scale-105 border border-[var(--indigo-11)] rounded'>
                        <NavigationMenu.Link
                            className="text-[var(--indigo-12)] hover:text-[var(--indigo-6)] px-3 py-2 text-[15px] font-medium leading-none no-underline outline-none focus:shadow-[0_0_0_2px] flex gap-1"
                            href="/quick-guide.pdf"
                        >
                            คู่มือการใช้งาน
                        </NavigationMenu.Link>
                    </NavigationMenu.Item>
                </NavigationMenu.List>
                <NavigationMenu.Indicator className="data-[state=visible]:animate-fadeIn data-[state=hidden]:animate-fadeOut top-full z-[1] flex h-[10px] items-end justify-center overflow-hidden transition-[width,transform_250ms_ease]">
                    <div className="relative top-[70%] h-[15px] w-[15px] rotate-[45deg] rounded-tl-[2px] bg-[var(--gray-5)]" />
                </NavigationMenu.Indicator>
            </div>
            <div className="perspective-[1000px] absolute top-full left-0 flex w-full justify-left">
                <NavigationMenu.Viewport className="data-[state=open]:animate-scaleIn data-[state=closed]:animate-scaleOut relative mt-[10px] h-[var(--radix-navigation-menu-viewport-height)] w-full origin-[top_center] overflow-hidden rounded-[12px] transition-[width,_height] duration-300 sm:w-[var(--radix-navigation-menu-viewport-width)] shadow bg-[var(--gray-5)] mx-3" />
            </div>
        </NavigationMenu.Root>
    );
};